<template>
  <div id="app">
    <div class="com__box" v-if="loading">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div id="book">
      <div
        ref="imgRef"
        class="page"
        v-for="(i, idx) in list"
        :class="idx == 0 ? 'page1' : idx == 7 ? 'page2' : ''"
        :key="idx + 1"
        :data-density="idx == 0 ? 'hard' : ''"
      >
        <div class="pos">
          <img v-lazy="i" />
          <div v-if="idx && idx !== list.length - 1" class="poa">
            <span>{{ idx }}</span>
            <span>/</span>
            <span>{{ list.length - 2 }}</span>
          </div>
          <img v-if="idx == 0" src="./assets/left.png" class="left" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PageFlip } from "page-flip";
export default {
  name: "App",
  data() {
    return {
      list: [
        require("./assets/1.png"),
        require("./assets/2.png"),
        require("./assets/3.png"),
        require("./assets/4.png"),
        require("./assets/5.png"),
        require("./assets/6.png"),
        require("./assets/7.png"),
        require("./assets/8.png"),
      ],
      num: 0, // 加载成功图片数量
      loading: true,

      screenWidth: 0,
    };
  },

  mounted() {
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      console.log("111");
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
      })();
    };
    this.ready(this.list);
    this.pageFlips();
  },
  watch: {
    screenWidth(v) {
      if (v) {
        this.pageFlips();
      }
    },
  },
  methods: {
    pageFlips() {
      this.$nextTick(() => {
        // 阻止默认的处理方式(阻止下拉滑动的效果)
        document.body.addEventListener(
          "touchmove",
          function (e) {
            e.preventDefault();
          },
          { passive: false }
        );

        // 翻页
        const { clientHeight, clientWidth } = document.documentElement;

        const pageFlip = new PageFlip(document.getElementById("book"), {
          width: clientWidth, //页面宽度
          height: clientHeight, //页面高度
          size: "fixed", // 书籍是否会在父元素下拉伸or "stretch"
          mobileScrollSupport: true, //在移动设备上触摸图书时禁用内容滚动
          drawShadow: true, // 翻页时是否绘制阴影
          autoSize: true, // 如果此值为 true，则父元素将等于书籍的大小
          maxShadowOpacity: 0.5, // 阴影强度（1：最大强度，0：隐藏阴影）
          showCover: false, // 如果此值为 true，则第一页和最后一页将被标记为硬页
        });
        pageFlip.loadFromHTML(document.querySelectorAll(".page"));

        pageFlip.on("flip", (e) => {
          console.log("Current page: " + e.data);
        });
      });
    },
    ready(pics) {
      let _this = this;
      const picsAll = pics.map((imgurl) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = imgurl;
          img.onload = () => resolve(imgurl);
          img.onerror = () => reject(new Error(imgurl + " load error"));
        });
      });
      Promise.all(picsAll)
        .then(() => {
          _this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
@import "./assets/index.css";

.com__box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  background: #f7e1bc;
  align-items: center;
  justify-content: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
.page {
  display: flex !important;
  align-items: center;
  background: url("./assets/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.left {
  position: absolute;
  width: 2.5rem !important;
  height: 2.5rem;
  right: 1rem;
  bottom: 7rem;
  animation: mymove 1.2s infinite;
  /* transition: all .5s; */
}
@keyframes mymove {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0.2;
  }
}

/*Safari 和 Chrome:*/
@-webkit-keyframes mymove {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0.2;
  }
}

.page1 {
  background: url("./assets/9.png"), url("./assets/1.jpg");
  background-position: center bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, cover;
}

.page2 {
  background: url("./assets/10.png"), url("./assets/1.jpg");
  background-position: center bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, cover;
}

.page img {
  width: 100vw;
}

.pos {
  position: relative;
}

.pos .poa {
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 100%;
  text-align: center;
}
.poa span {
  display: inline-block;
  /* font-style: italic; */
  margin: 0 0.2vw;
  font-size: 0.8rem;
}
/* .page1 {
  background: url("./assets/01.jpg") no-repeat;
  background-size: cover;
}
.page2 {
}
.page3 {
  background: url("./assets/03.jpg") no-repeat;
  background-size: cover;
} */
</style>
